import React from "react";
import * as Icon from "react-feather";

const navigationConfig = [
  {
    id: "homeDash",
    title: "Home",
    type: "item",
    icon: <Icon.Home size={12} />,
    permissions: ["admin", "editor"],
    navLink: "/",
  },
  {
    id: "analyticsDash",
    title: "Analytics",
    type: "item",
    icon: <Icon.PieChart size={12} />,
    permissions: ["admin"],
    navLink: "/analytics",
  },
];

export default navigationConfig;
