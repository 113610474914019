import React, { useState } from "react";
import brandLogo from "../../../assets/img/logo/kilifi-county-logo.png";
import { Form, FormGroup, Input } from "reactstrap";
import { Search } from "react-feather";
import * as Icon from "react-feather";
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,
} from "reactstrap";
import NavbarBookmarks from "./NavbarBookmarks";

const ThemeNavbar = (props) => {
  const [collapsed, setCollapsed] = useState(true);

  const toggleNavbar = () => setCollapsed(!collapsed);

  return (
    <React.Fragment>
      <Navbar bg="light" expand="lg" className="shadow-sm"> 
        <div className="container-lg">
          <NavbarBrand href="/">
            <img
              src={brandLogo}
              alt="Kilifi County Logo"
              height="50"
              width="50"
              draggable="false"
            />
            Kilifi Open Data
          </NavbarBrand>

          {!props.horizontal && (
            <NavbarBrand onClick={props.sidebarVisibility}>
              <Icon.Menu className="ficon" />
            </NavbarBrand>
          )}

          {/* <NavbarToggler onClick={props.sidebarVisibility} /> */}

          <Collapse isOpen={!collapsed} navbar>
            <Nav className="mr-auto" navbar>
              <NavItem>
                <NavLink href="/">Home</NavLink>
              </NavItem>
              <NavItem>
                <NavLink href="/analytics">Analytics</NavLink>
              </NavItem>
            </Nav>
            {/* <Nav className="ml-auto">
              <Form className="stat-filters-search-form">
                <label htmlFor="search">Find projects in Kilifi County</label>
                <FormGroup className="search-form has-icon-left">
                  <Input
                    type="text"
                    className="rounded-input"
                    placeholder="Search by project name or code..."
                    value={this.props.projectsData.searchQuery}
                    onChange={(e) => this.props.updateSearchQuery(e)}
                    onKeyPress={(e) => {
                      if (e.key === "Enter") {
                        history.push("/search");
                      }
                    }}
                  />
                  <div className="form-control-position">
                    <Search size={15} />
                  </div>
                </FormGroup>
              </Form>
            </Nav> */}
          </Collapse>
        </div>
      </Navbar>
    </React.Fragment>
  );
};

export default ThemeNavbar;
