const initialState = {
  projectsData: {
    horizontal: null,
    allBoundaries: [],
    departmentsOptions: [],
    subCountiesOptions: [],
    projectsStateOptions: [],
    wardsOptions: [],
    currentSubCounty: [],
    currentWard: [],
    currentDepartment: [],
    currentState: [],
    projects: [],
    projectsStatus: {},
    departmentStats: [],
    involvement: {},
    priorities: {},
    defects: {},
    financialSummary: {},
    financialReport: [],
    searchQuery: "",
    totalProjects: "",
    totalProjectsStatus: {},
    loading: true,
  },
};

const projectsReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case "SET_PROJECT_DATA": {
      return {
        ...state,
        projectsData: action.payload,
      };
    }
    case "SET_ORIENTATION": {
      return {
        ...state,
        projectsData: {
          ...state.projectsData,
          horizontal: action.horizontal,
        },
      };
    }
    case "CHANGE_CURRENT_SUBCOUNTY": {
      return {
        ...state,
        projectsData: {
          ...state.projectsData,
          currentSubCounty: action.currentSubCounty,
        },
      };
    }
    case "CHANGE_WARD_OPTIONS": {
      return {
        ...state,
        projectsData: {
          ...state.projectsData,
          wardsOptions: action.wardsOptions,
        },
      };
    }
    case "UPDATE_WARD_AND_PROJECT_DATA": {
      return {
        ...state,
        projectsData: {
          ...state.projectsData,
          currentWard: action.currentWard,
          projectsStatus: action.projectsStatus,
          involvement: action.involvement,
          priorities: action.priorities,
          departmentStats: action.departmentStats,
          projects: action.projects,
          defects: action.defects,
          loading: false,
        },
      };
    }
    case "UPDATE_DEPARTMENT_AND_PROJECT_DATA": {
      return {
        ...state,
        projectsData: {
          ...state.projectsData,
          currentDepartment: action.currentDepartment,
          projectsStatus: action.projectsStatus,
          involvement: action.involvement,
          priorities: action.priorities,
          departmentStats: action.departmentStats,
          projects: action.projects,
          defects: action.defects,
          loading: false,
        },
      };
    }
    case "UPDATE_STATE_AND_PROJECT_DATA": {
      return {
        ...state,
        projectsData: {
          ...state.projectsData,
          currentState: action.currentState,
          projectsStatus: action.projectsStatus,
          involvement: action.involvement,
          priorities: action.priorities,
          departmentStats: action.departmentStats,
          loading: false,
        },
      };
    }
    case "UPDATE_SEARCH_QUERY": {
      return {
        ...state,
        projectsData: {
          ...state.projectsData,
          searchQuery: action.searchQuery,
        },
      };
    }
    case "SET_LOADING": {
      return {
        ...state,
        projectsData: {
          ...state.projectsData,
          loading: action.loading,
        },
      };
    }
    default:
      return state;
  }
};

export default projectsReducer;
